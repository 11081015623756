<template>
	<div class="subscriptions">
		<div 
			class="subscription"
			v-for="subscription in subscriptions"
			:key="subscription.name"
		>
			<div class="header">
				<h2>{{ subscription.name }}</h2>
				<span>${{ subscription.price }}</span>
			</div>
			<p>{{ subscription.description }}</p>
			<button type="button" @click="processSubscription(subscription.priceId)">Subscribe</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Subscriptions',
	data: () => ({
		subscriptions: [{
			name: 'Pro',
			price: 49,
			description: 'Fully featured solution for growing companies',
			priceId: 'price_1LZy6yDX3XD97BffrilsR6CF'
		}]
	}),
	methods: {
		async processSubscription(priceId) {
			window.location.href = 'https://devdima.dev.statica.pl/test/streamcat/dist/?processSubscription=' + priceId;
		}
	}
}
</script>

<style lang="sass" scoped>
.subscriptions
	padding: 30px
	.subscription
		padding: 20px 40px
		display: flex
		flex-direction: column
		align-items: center
		width: 300px
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px
		border: 1px solid #d7d7d7
		.header
			width: 100%
			display: flex
			justify-content: space-between
			border-bottom: 1px solid #d7d7d7
			padding-bottom: 5px
			span
				font-size: 18px
				font-weight: bold
		p
			text-align: center
			margin-top: 20px
		button
			width: 100%
			margin-top: 20px
			font-size: 16px
			padding: 10px 0
			color: #fff
			border-radius: 10px
			background-color: #9c1b65
			&:hover
				background-color: rgba(#9c1b65, 0.9)
				cursor: pointer
</style>